@media only screen and (max-width: 1440px) {
	.site-footer {
		.left-side {
			padding-left: 0;
		}
	}
	.home-page-template {
		.progressBarContainer {
			width: calc(100% + 30px);
			margin-left: -15px;
		}
	}
	.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container {
		padding-left: 30px;
		padding-right: 30px;
	}
	#fullpage {
		.project-item {
			a {
				.logo-width {
					max-width: 20%;
				}
			}
		}
	}
	.navbar {
		.row {
			.col-12 {
				.logo-top {
					padding-left: 0;
				}
				.menu {
					margin-right: 0;
				}
			}
		}
	}
	.navbar-mont {
		.logo-top {
			padding-left: 0;
		}
	}
	.culture-page-template {
		.culture-top {
			h1 {
				font-size: 47px;
			}
		}
	}
	.collaborations-page-template {
		.culture-top {
			h1 {
				font-size: 47px;
			}
		}
	}
	.collaborations-page-template .back-zoom-height .zoom-center-animation .on-top:nth-child(4)>div .zoon-anim .copy-on-top p {
		width: 580px;
	}
	.full-project {
		.corp-social-posts {
			.corp-social-animation {
				&>div {
					img {
						width: 250px;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 1340px)  {
	.contact-page-template {
		.contact-copy {
			h6 {
				margin-left: 50px;
			}
		}
	}
	.careers-page-template  {
		.careers-copy {
			h6 {
				margin-left: 50px;
			}
		}
	}
	.single-careers {
		.careers-desc {
			.back-to-careers {
				margin-left: 32px;
			}
		}
	}
	.culture-page-template {
		.awards {
			h6 {
				margin-left: 50px;
			}
		}
	}
	.collaborations-page-template {
		.awards {
			h6 {
				margin-left: 50px;
			}
		}
	}
	.full-project {
		.website-bg {
			h3 {
				margin-left: 50px;
			}
		}
		.more-projects {
			h3 {
				margin-left: 50px;
			}
		}
	}
}
@media only screen and (max-width: 1280px)  { 
	.home-page-template {
		.dark-bg {
			.slick {
				.slick-dots {
					padding-left: calc(50px + (100vw / 12 ) + 15px);
				}
			}
		}
	}
	.all-projects-page-template {
		.featured-projects-slider-container {
			.for-title {
				h6 {
					margin-left: 100px;
				}
				.featured-projects-slider, .master-planned-communities-projects-slider, .high-rise-projects-slider, .low-rise-projects-slider, .purpose-built-rentals-projects-slider, .hotel-entertainment-projects-slider, .hospitality-commercial-projects-slider, .public-service-projects-slider, .corporate-real-estate-slider {
					margin-left: 30px;
					.slick-list {
						padding-left: 0px;
					}
				}
			}
		}
	}
}
// Large devices (desktops, less than 1200px)
@include media-breakpoint-down(lg) {
	.navbar {
		&.scroll-active {
			.row {
				.col-12 {
					.menu {
						.hamburger {
							margin-right: 0;
						}
					}
				}
			}
		}
		.row {
			.col-12 {
				.logo-top {
					// padding-left: 15px;
				}
				.menu {
					// margin-right: 15px;
				}
				.navbar-nav {
					li {
						.nav-link {
							padding-right: 20px;
							padding-left: 20px;
						}
						&:last-child {
							.nav-link {
								padding-right: 0px;
							}
						}
					}
				}
			}
		}
	}
	.all-projects-page-template {
		.featured-projects-slider-container {
			.for-title {
				h6 { 
					margin-left: 100px;
				}
				.featured-projects-slider, .master-planned-communities-projects-slider, .high-rise-projects-slider, .low-rise-projects-slider, .purpose-built-rentals-projects-slider, .hotel-entertainment-projects-slider, .hospitality-commercial-projects-slider, .public-service-projects-slider, .corporate-real-estate-slider {
					.slick-list {
						// padding-left: 80px;
					}
				}
			} 
		}
	}
	.careers-page-template {
		.careers-copy {
			.col-xl-8 {
				margin-top: 55px;
				.position-list {
					a.arrow {
						font-size: 0;
					}
				}
			}
		}
	}
	.capabilities-page-template {
		.capabilities-banner {
			.bg-image {
				img {
					z-index: unset;
				}
			}
		}
		.capabilities-copy {
			.next-arrow {
				.next-post {
					white-space: unset;
					writing-mode: unset;
					text-orientation: unset;
					top: unset;
					bottom: 0;
					width: 50vw;
					right: 0;
					transform: unset;
					a {
						min-height: unset;
						span {
							display: inline-block;
						}
					}
				}
			}
			.prev-arrow {
				top: unset;
				bottom: 0;
				width: 50vw;
				left: 0;
				transform: unset;
				.prev-post {
					white-space: unset;
					writing-mode: unset;
					text-orientation: unset;
					a {
						min-height: unset;
						span {
							display: inline-block;
						}
					}
				}
			}
			h5 {
				margin-left: 50px;
			}
			.col-xl-7 {
				&:nth-child(3) {
					margin-bottom: 40px;
				}
			}
		}
	}
	// culture 
	.home-page-template {
		.back-zoom-height {
			.zoom-center-animation {
				.on-top {
					.gradient-for-copy {
						div {
							left: 50%;
						}
					}
				}
			}
		}
	}
	
	.full-project {
		.corp-social-posts {
			.corp-social-animation {
				&>div {
					&:first-child {
						display: none;
					}
				}
			}
		}
		
		// .top-project-banner {
		// 	.copy-bottom-screen {
		// 		.flex-lists {
		// 			div {
		// 				margin-right: 100px;
		// 			}
		// 		}
		// 	}
		// }
		
		.about-project {
			.about-top {
				.about-copy {
					h3 {
						margin-left: 75px;
					}
				}
			}
			.about-video {
				.start-video {
					cursor: unset !important;
				}
				.mobile-play-pause {
					display: block;					
				}
			}
		}
		.social-campaign {
			.social-flex, .social-flex-pizza {
				&.type1 {
					.social-item {
						padding-right: 50px;
					}
				}
				&.type2 {
					.social-item {
						padding-left: 50px;
					}
				}
				h3 {
					margin-left: 50px;
				}
			}
		}
		.campaign-campaign {
			.campaign-flex {
				h3 {
					margin-left: 60px;
				}
			}
		}
	}
	.collaborations-page-template .back-zoom-height .zoom-center-animation .on-top:nth-child(4)>div .zoon-anim .copy-on-top p {
		width: 460px;
	} 
}

// Medium devices (tablets, less than 992px)
@include media-breakpoint-down(md) {
	.navbar {
		.row {
			.col-12 {
				.menu {
					.hamburger {
						opacity: 1;
						position: relative;
						z-index: 1;
					}
				}
			}
		}
	}
  	.menu-popup {
		.col-md-6 {
			img {
				display: none;
			}
		}
	}
	.site-footer {
		.top-row-footer-flex {
			flex-direction: column;
			padding-bottom: 26px;
		}
		#footer-nav {
			padding-top: 26px;
		}
		.bottom-row-footer-flex {
			flex-direction: column-reverse;
		}
		.social {
			padding-top: 0;
			padding-bottom: 50px;
		}
		.left-side {
			.address {
				flex-direction: column;
				padding-bottom: 24px;
			}
		}
	}
	.home-page-template {
		.slice {
			display: none;
		}
		.back-zoom-height {
			display: none;
		}
		.mobile-collage {
			display: block;
			margin-bottom: 0;
			margin-top: 0px;
			// padding: 0 20px;
			.featured-project-mobile {
				background-image: url('/wp-content/uploads/2024/03/new-f-mob.jpg');
				margin-top: 0px;
				padding: 300px 30px 200px 30px;
				// padding-bottom: 0;
				background-position: center;
				background-size: cover;
				h2 {
					font-size: 48px;
					color: $white;
				}
				a {
					color: $white;
				}
			}
		}
		
	}
	// culture 
	.culture-page-template {
		.back-zoom-height {
			display: none;
			height: 0;
			margin-top: 0;
		}
		.mobile-collage {
			display: block;
			margin-bottom: 0px;
			margin-top: 70px;
			// padding: 0 20px;
		}
		.no-mobile-tik-toks  {
			display: none;
		}
		.clients {
			padding-top: 80px;
			padding-bottom: 88px;
			h2 {
				margin-bottom: 40px;
				font-size: 36px;
			}
			.logos {
				img {
					width: calc(100% / 5);
				}
			}
		}
		.awards {
			padding-top: 80px;
			padding-bottom: 88px;
			h2 {
				font-size: 36px;
			}
		}
	}
	.collaborations-page-template {
		.back-zoom-height {
			display: none;
			height: 0;
			margin-top: 0;
		}
		.mobile-collage {
			display: block;
			margin-bottom: 0px;
			margin-top: 70px;
			// padding: 0 20px;
		}
		.clients {
			padding-top: 80px;
			padding-bottom: 88px;
			h2 {
				margin-bottom: 40px;
				font-size: 36px;
			}
			.logos {
				img {
					width: calc(100% / 5);
				}
			}
		}
		.awards {
			padding-top: 80px;
			padding-bottom: 88px;
			h2 {
				font-size: 36px;
			}
		}
	}
	.join-team {
		h3 {
			font-size: 36px;
		}
	}
	.full-project {
		.corp-social-posts {
			.corp-social-animation {
				&>div {
					&:nth-child(2) {
						display: none;
					}
				}
			}
		}
		&.project-300, &.project-320, &.project-569, &.project-781, &.project-894 {
    		.logo-dark {
				max-width: unset;
				margin: auto;
			}
		}
		.see-you-soon-gear, .see-you-soon-art, .to-flag-gear, .to-flag-art, .smash-gear {
			display: none;
		}
		.dark-opacity-level {
			opacity: 0.4 !important;
		}
		.pizza-nova-gear {
			margin-top: 40px;
			.gear-flex {
				display: none;
			}
			.gear-flex-mob {
				display: block;
				padding-bottom: 50px;
				img {
					margin-top: -20px;
				}
				h3 {
					font-size: 16px;
					margin-left: 100px;
					margin-top: -30px;
					font-weight: 400;
					color: $white;
				}
			}
		}
		.pizza-nova-art {
			padding-top: 80px;
			padding-bottom: 80px;
			.art-flex {
				h3 {
					position: relative;
					top: unset;
					right: unset;
					margin-left: 100px;
				}
			}
		}
		.top-project-banner {
			.copy-top-screen {
				h1 {
					text-indent: 0;
					font-size: 100px;
				}
			}
			.copy-bottom-screen {
				padding-bottom: 180px;
				&>p {
					width: 100%;
				}
				.flex-lists {
					div {
						margin-right: 50px;
					}
				}
			}
		}
		.logo-dark {
			max-width: 220px;
			margin: auto;
		}
		.about-project .about-top .about-copy h3::before {
			display: none;
		}
		.about-project .about-top .about-image {
			margin-left: 30px;
		}
		.project-brand h3::before {
			display: none;
		}
		.project-brand h3 {
			margin-left: 0;
		}
		.project-brand p {
			padding-left: 0;
		}
		.about-project .about-top .about-copy {
			h3 {
				margin-left: 30px;
			}
			p {
				margin-left: 30px;
			}
		}
		.back-zoom-height {
			// display: none;
			height: auto;
			margin-bottom: 32px;
			.zoom-center-animation {
				height: fit-content;
				.on-top {
					position: relative !important;
					top: unset !important;
					left: unset !important;
					bottom: unset !important;
					right: unset !important;
					margin-bottom: 16px;
					padding: 0 30px;
					img {
						position: relative !important;
						top: unset !important;
						left: unset !important;
						right: unset !important;
						bottom: unset !important;
						width: 100% !important;
						height: auto !important;
						transform: unset !important;
					}
				}
			}
		}
		.mobile-collage {
			display: block;
			margin-bottom: 80px;
			margin-top: 70px;
			// padding: 0 20px;
		}
		.campaign-campaign {
			.campaign-flex {
				.campaign-item {
					width: 45%;
				}
			}
		}
		.social-campaign .mob-title h3{
			margin-left: 30px;
			&::before {
				display: none;
			}
		}
		.about-project {
			padding-bottom: 0px;
			.about-top {
				flex-direction: column-reverse;
				.about-image {
					margin-right: auto;
					// margin-left: 75px;
					margin-bottom: 24px;
					margin-right: auto;
					max-width: 120px;
				}
			}
			.about-video {
				width: 100%;
				
			}
		}
		.sales-center {
			justify-content: end;
			.sales-center-image {
				width: calc(100% - 75px - 180px);
			}
		}
		.projects-slider {
			.project-item {
				a {
					.f-image {
						height: 410px;
					}
				}
			}
		}
	}
	#fullpage {
		.project-item {
			height: 100vh;
			.mobile-no-link {
				display: block;
				.center-main-width {
					display: none;
				}
				.logo-width {
					left: 50%;
					transform: translate(-50%, -50%);
					max-width: 80%;
					max-height: 200px;
					height: 100%;
					width: 100%;
					top: 40%;
					.logo {
						height: 100%;
						object-fit: contain;
						width: 100%;
					}
					.view-for-mobile {
						display: block;
						position: absolute;
						top: calc(100% + 48px);
						left: 50%;
						transform: translateX(-50%);
						a {
							background-color: $primary;
							color: $white;
							width: 72px;
							height: 72px;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 50%;
							font-size: 14px;
							letter-spacing: 0.02em;
							
						}
					}
				}
			}
			.desktop-link {
				display: none;
				.center-main-width {
					display: none;
				}
				.logo-width {
					left: 50%;
					transform: translate(-50%, -50%);
					max-width: 80%;
					max-height: 200px;
					height: 100%;
					width: 100%;
					top: 40%;
					.logo {
						height: 100%;
						object-fit: contain;
						width: 100%;
					}
					.view-for-mobile {
						display: block;
						position: absolute;
						top: calc(100% + 48px);
						left: 50%;
						transform: translateX(-50%);
						background-color: $primary;
						color: $white;
						width: 72px;
						height: 72px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						p {
							font-size: 14px;
							letter-spacing: 0.02em;
						}
					}
				}
			}
		}
	}
	.full-page-js-template {
		.all-projects-link {
			bottom: 0;
			left: 0;
			width: 100%;
			height: 48px;
			display: flex;
			align-items: center;
			text-align: right;
			background-color: $primary;
			a {
				position: absolute;
				right: 60px;
				&::after {
					background-image: url('/wp-content/uploads/2023/01/arrow-dark.svg');
				}
			}
		}
		.count-full-page {
			bottom: 80px;
			right: 41px;
		}
	}
}

// Small devices (landscape phones, less than 768px)
@include media-breakpoint-down(sm) {
	.menu-popup.menu-open .menu-left-side .social-media {
		margin-top: 48px;
	}
	.menu-popup {
		padding-top: 20px;
	}
	.custom-cursor {
		display: none !important;
	  }
	.custom-cursor .cursor-text {
		display: none !important;
	}
	// common 
	p {
		font-size: 14px;
	}
	// menu
	.menu-popup {
		.menu-left-side {
			#header-nav {
				.navbar-nav {
					padding-left: 50px;
					.nav-link {
						font-size: 36px;
					}
				}
			}
		}
	}
	// contact us 
	.contact-copy {
		.mobile-hide-numbers {
			display: none;
		}
	}
	.contact-page-template {
		.contact-banner {
			margin-top: -12%;
		}
		#map {
			height: 250px;
			margin-left: -30px;
			width: calc(100% + 60px);
		}
		.contact-copy {
			padding-top: 56px;
			padding-bottom: 88px;
			h1 {
				font-size: 27px;
				margin-bottom: 24px;
			}
			h6 {
				font-size: 14px;
			}
			.mobile-show-numbers {
				padding-top: 48px;
				display: block;
				p {
					a {
						color: $black;
					}
				}
			}
		}
	}
	// careers 
	.careers-page-template {
		.careers-banner {
			margin-top: -12%;
		}
		.careers-copy {
			padding-top: 55px;
			padding-bottom: 80px;
			h2 {
				font-size: 27px;
			}
			h6 {
				font-size: 14px;
			}
			p {
				font-size: 14px;
			}
			.position-list {
				.title {
					h3 {
						font-size: 20px;
						padding-right: 20px;
					}
					p {
						font-size: 12px;
					}
				}
			}
		}
	}
	.single-careers {
		.careers-desc {
			.back-to-careers {
				margin-bottom: 30px;
			}
		}
		.submit-form {
			.for-form {
				padding: 20px;
			}
		}
		.desc {
			p {
				font-size: 14px;
			}
			ul {
				li {
					font-size: 14px;
				}
			}
			.for-skills {
				div {
					padding: 8px 20px;
				}
				p {
					font-size: 12px;
				}
			}
		}
		.apply-now {
			a.arrow {
				font-size: 16px;
			}
		}
		.careers-desc {
			.back-to-careers {
				a.arrow {
					font-size: 0;
				}
			}
			.col-xl-4 {
				padding-right: 15px;
				.short-info {
					margin-right: 0;
					margin-bottom: 48px;
				}
				h1 {
					font-size: 24px;
				}
				a.arrow {
					font-size: 14px;
				}
			}
		}
	}
	// Capabilities 
	.capabilities-page-template {
		.capabilities-banner {
			width: 100%;
			.all-options {
				position: absolute;
				
				top: 53%;
				left: 40px;
				right: unset;
				transform: translateY(-50%);
				a {
					font-size: 22px;
					opacity: 1;
					transform: unset;
					span {
						font-size: 10px;
						left: -20px;
					}
				}
			}
			.bg-image {
				.video-spl {
					.container-vimeo {
						max-width: 100%;
					}
				}
			}
		}
		.capabilities-copy {
			h5 {
				font-size: 14px;
				margin-bottom: 20px;
			}
			div {
				.services {
					flex-direction: column;
					&>div {
						&:first-child {
							p:last-child {
								border-bottom: 1px solid #D9D9D9;
							}
						}
					}
				}
				&.black {
					.services {
						flex-direction: column;
						&>div {
							&:first-child {
								p:last-child {
									border-bottom: 1px solid #7d7d7d;
								}
							}
						}
					}
				}
			}
			
		}
		.capabilities-copy>div {
			padding: 48px 0;
			padding-bottom: 120px;
			h2 {
				font-size: 30px;
			}
			p {
				font-size: 14px;
			}
		}
	}
	
	.home-page-template {
		.container-vimeo {
			max-width: 100%;
		}
		.slick .slick-slide,
		.slick .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"]{
			h2 {
				font-size: 48px;
			}
			p {
				font-size: 14px;
			}
		}
		.dark-bg {
			.slick {
				margin: 100px 0;
				.container {
					padding: 0;
				}
				.slick-dots {
					padding-left: 0;
					padding-top: 10px;
					a {
						font-size: 14px;
					}
				}
			}
		}
		.horizontal-scroll1, .horizontal-scroll2 {
			display: none;
		}
		.mobile-no-hor-scroll {
			display: block;
		}
		.back-zoom-height {
			.zoom-center-animation {
				.on-top {
					&:first-child {
						img {
						}
					}
					&:nth-child(2) {
						img {	
						}
					}
					&:nth-child(3) {
						img {
							left: -10vw;
							width: 33.16667vw;
						}
					}
					&:nth-child(4) {
						img {
							width: 48vw;
						}
					}
					.gradient-for-copy {
						div {
							h2 {
								font-size: 15px;
							}
							a {
								font-size: 4px;
							}
						}
					}
					&:nth-child(5) {
						img {
							width: 28.25vw;
						}
					}
					&:nth-child(6) {
						img {

						}
					}
					&:nth-child(7) {
						img {

						}
					}
				}
			}
		}
		.capabilities {
			h2 {
				font-size: 52px;
			}
		}
		.horizontal-scroll2 {
			margin-bottom: 120px;
		}
		.culture {
			padding: 300px 0 200px 0;
			// padding-top: 48px;
			// padding-bottom: 112px;
			// background-image: none;
			h2 {
				font-size: 52px;
				// color: $black;
			}
			// p {
			// 	color: $black;
			// }
			// a {
			// 	color: $black;
			// }
		}
		
		.capabilities {
			padding-top: 48px;
			padding-bottom: 96px;
		}
		.dark-bg {
			.slick {
				.slick-dots {
					li {
						padding-right: 47px;
					}
				}
			}
		}
	}
	a.arrow {
		font-size: 16px;
	}
	// footer 
	.site-footer {
		.back-top-top-ms {
			right: 40px;
			top: 50px;
			img {
				width: 24px;
			}
		}
		.right-side {
			padding-right: 0;
		}
		.left-side {
			.copy {
				h3 {
					font-size: 29px;
				}
				p {
					font-size: 14px;
				}
			}
			.address {
				p {
					font-size: 14px;
				}
			}
			.logo-footer {
				img {
					width: 63px;
				}
			}
		}
		#footer-nav {
			.navbar-nav {
				.nav-link {
					padding: 5px 0;
				}
			}
			ul {
				li {
					a {
						font-size: 14px;
					}
				}
			}
		}
		.social {
			.social-media {
				.social-media-item {
					margin-right: 10px;
				}
			}
			h4 {
				font-size: 12px;
			}
			.fa-2x {
				font-size: 1.5em;
			}
		}
	}
	// culture 
	.culture-page-template {
		.culture-top {
			padding-top: 128px;
			h1 {
				font-size: 24px;
			}
		}
		.no-mobile-tik-toks {
			.big-text {
				padding: 80px 0 60px 0;
				h2 {
					font-size: 42px;
				}
			}
			.flex-tik-tok > div {
				width: 50%;
			}
		}
		.back-zoom-height {
			.zoom-center-animation {
				.on-top {
					&:first-child {
						img {
							left: 7.7vw;
							width: 60vw;
						}
					}
					&:nth-child(2) {
						img {
							width: 37.25vw;
							left: 72.5vw;
						}
					}
					&:nth-child(3) {
						img {
							left: -28.5vw;
							width: 31.16667vw;
						}
					}
					&:nth-child(4) {
						img {
							width: 85vw;
						}
					}
					&:nth-child(5) {
						img {
							left: 97vw;
						}
					}
					&:nth-child(6) {
						img {
							left: 2.06944vw;
							width: 50.25vw;
						}
					}
					&:nth-child(7) {
						img {
							left: 55.93056vw;
						}
					}
				}
			}
		}
		.horizontal-scroll1, .horizontal-scroll2 {
			display: none;
		}
		.horizontal-scroll-box {
			padding-top: 90px;
			padding-bottom: 90px;
		}
		.mobile-no-hor-scroll {
			display: block;
			img {
				width: 100%;
			}

		}
		.awards {
			.mob-awards {
				display: block;
			}
			.desk-awards {
				display: none;
			}
		}
		.card {
			.card-body {
				padding: 0px 60px 30px 25px;
			}
		}
	}
	.collaborations-page-template {
		.culture-top {
			padding-top: 128px;
			h1 {
				font-size: 24px;
			}
		}

		.back-zoom-height {
			.zoom-center-animation {
				.on-top {
					&:first-child {
						img {
							left: 7.7vw;
							width: 60vw;
						}
					}
					&:nth-child(2) {
						img {
							width: 37.25vw;
							left: 72.5vw;
						}
					}
					&:nth-child(3) {
						img {
							left: -28.5vw;
							width: 31.16667vw;
						}
					}
					&:nth-child(4) {
						img {
							width: 85vw;
						}
					}
					&:nth-child(5) {
						img {
							left: 97vw;
						}
					}
					&:nth-child(6) {
						img {
							left: 2.06944vw;
							width: 50.25vw;
						}
					}
					&:nth-child(7) {
						img {
							left: 55.93056vw;
						}
					}
				}
			}
		}
		.horizontal-scroll1, .horizontal-scroll2 {
			display: none;
		}
		.horizontal-scroll-box {
			padding-top: 90px;
			padding-bottom: 90px;
		}
		.mobile-no-hor-scroll {
			display: block;
			img {
				width: 100%;
			}

		}
		.awards {
			.mob-awards {
				display: block;
			}
			.desk-awards {
				display: none;
			}
		}
		.card {
			.card-body {
				padding: 0px 60px 30px 25px;
			}
		}
	}

	// Project internal page
	.full-project {
		.banner-project {
			height: unset !important;
			.next-project, .prev-project {
				display: none;
			}
		}
		.corp-social-posts {
			.project-corp-social {
				padding-top: 80px;
				padding-bottom: 80px;
				margin: auto;
			}
			.corp-social-animation {
				position: relative;
				&>div {
					&:nth-child(2) {
						display: block;
					}
				}
			}
		}
		.white-side-project {
			.for-iframe-video {
				margin-bottom: 80px;
				margin-top: 80px;
			}
		}
		.corp-website-all-screens-flex {
			margin-bottom: 80px;
			.corp-website-all-screen {
				width: 100%; 
				.project-corp-website {
					margin-top: 0px;
					margin-bottom: 0px;
				}
				img {
					display: none;
				}
			}
		}
		.top-project-banner {
			.copy-top-screen {
				position: relative;
				top: unset;
				transform: unset;
				padding-top: 150px;
				
			}
			.copy-bottom-screen {
				padding-top: 0;
				padding-bottom: 100px;
				padding-right: 70px;
			}
		}
		.brochure.brochure-to-center {
			margin-left: 0;
		}
		.sales-center.brochure-to-center {
			.sales-center-image {
				margin-right: 0;
			}
		}
		.sales-center {
			margin-top: -10px;
			&.type3 {
				flex-direction: column-reverse;
			}
			&.type2 {
				flex-direction: column-reverse;
			}
			&.type1 {
				flex-direction: column-reverse;
			}
			.sales-center-title {
				padding-right: 30px;
				transform: unset !important;
				padding-top: 25px;
				h3 {
					margin-bottom: 0;
				}
			}
			.sales-center-image {
				transform: unset !important;
				width: 70%;
			}
		}
		.brochure {
			margin-top: 80px;
			.brochure-image {
				width: 85%;
			}
			.brochure-title {
				padding-left: 60px;
				transform: unset !important;
				margin-bottom: 35px;
				h3 {
					margin-top: 0;
				}
			}
			&.type3 {
				flex-direction: column-reverse;
			}
			&.type2 {
				flex-direction: column-reverse;
			}
			&.type1 {
				flex-direction: column-reverse;
			}
		}
		.website-bg.website-padding {
			margin-top: 80px;
		}
		.top-project-banner {
			.copy-top-screen {
				opacity: 1 !important;
				padding-left: 15px;
				h1 {
					font-size: 70px;
					transform: none !important;
					margin-bottom: 20px;
				}
				.project-and-cat {
					transform: none !important;
					span {
						font-size: 14px;
					}
				}
			}
			.copy-bottom-screen{
				padding-left: 15px;
				&>p {
					font-size: 16px;
					transform: none !important;
				}
				.flex-lists {
					flex-direction: column;
					margin-top: 20px;
					transform: none !important;
					div {
						margin-right: 0;
						h3 {
							font-size: 16px;
						}
						&.flex-clients {
							margin-bottom: 20px;
							
							&:nth-child(3) {
								display: none;
							}
							p {
								margin-bottom: 4px;
							}
						}
					}
				}
			}
		}
		.logo-dark {
			padding-top: 80px;
		}
		.about-project {
			padding-top: 80px;
		}
		.project-corp-website {
			h3 {
				margin-left: 55px;
				&::before {
					right: calc(100% + 15px);
				}
			}
		}
		
		p {
			font-size: 14px;
		}
		.brochure .brochure-title h3 {
			display: none;
		}
		.sales-center .sales-center-title {
			display: none !important;
		}
		.brochure {
			.brochure-image {
				width: 100%;
				padding: 0 30px;
				margin-bottom: 16px;
			}
		}
		.sales-center {
			margin-top: 0;
			margin-bottom: 32px;
			.sales-center-image {
				width: 100%;
				padding: 0 30px;
				img {
					margin-bottom: 16px;
				}
			}
		}
		
		.social-campaign {
			padding-top: 50px;
			margin-bottom: 80px;
			.mob-title {
				display: none;
				h3 {
					// margin-left: 75px;
					margin-bottom: 80px;
					margin-top: 60px;
				}
			}
			.social-flex-pizza.reverse{
				transform:rotate(180deg);
			}
			.social-flex-pizza.reverse .slick-slide{
				transform:rotate(180deg);
			}
			.social-flex, .social-flex-pizza {
				display: block;
				h3 {
					display: none;
				}
				.social-item {
					&.mobile-no-item {
						display: none !important;
					}
					// width: auto !important;
					padding: 0 !important;
					margin: 0 !important;
					// max-width: 100%;
					img {
						margin: auto !important;
						padding: 20px;
					}
					.video-play {
						margin: 20px;
						img {
							padding: 0;
						}
					}
				}
				
			}
		}
		.campaign-campaign {
			padding-top: 88px;
			margin-bottom: 100px;
			.campaign-flex {
				flex-wrap: wrap;
			}
			.campaign-item {
				&:nth-child(3), &:nth-child(4) {
					display: none;
				}
			}
		}
		.more-projects h3::before {
			display: none;
		}
		.more-projects h3 {
			margin-left: 0;
		}
		.projects-slider .project-item {
			&:first-child {
				padding-left: 30px;
			}
		}
		.website-bg {
			margin-top: 0 !important;
			background-image: none !important;
			padding-top: 0;
			padding-bottom: 0;
			margin-bottom: 77px;
			h3 {
				display: none;
			}
			.container {
				padding-left: 0;
				padding-right: 0;
				.row {
					margin-left: 0;
					margin-right: 0;
					.col-md-12 {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
	.bg-for-height-no-animation {
		height: auto !important;
	}
	.culture-page-template {
		.back-zoom-height {
			display: block;
			height: auto;
			.zoom-center-animation {
				height: auto;
				.for-new-zoom {
					width: 100%;
					height: auto;
					position: relative;
					top: unset;
					left: unset;
					transform: unset;
					.on-top {
						position: relative !important;
						top: unset !important;
						right: unset !important;
						bottom: unset !important;
						left: unset !important;
						transform: unset !important;
						padding: 0 30px;
						margin-bottom: 16px;
						.split-screen-videos {
							display: none;
						}
						img {
							top: unset !important;
							left: unset !important;
							width: unset !important;
							height: unset !important;
							position: relative;
							transform: unset !important;
						}
					}
				}
			}
		}
	}
	.capabilities-page-template {
		.capabilities-banner {
			opacity: 0;
			height: 0px;
			min-height: unset;
			margin-top: unset;
		}
		.capabilities-switch-mob {
			display: block;
			.dropdown-container {
				padding-top: 112px;
			}
		}
		.capabilities-copy {
			h5 {
				margin-left: 0;
				color: $primary;
				&::before {
					display: none;
				}
			}
		}
	}


	.full-project {
		.corp-social-posts {
			.corp-social-animation {
				&>div {
					margin-left: 32px;
				}
			}
		}
		.top-project-banner {
			.copy-top-screen {
				h1 {
					font-size: 48px;
				}
			}
		}
		.projects-slider {
			.project-item {
				a {
					.f-image {
						height: 370px;
					}
				}
			}
		}
		.project-corp-website {
			p {
				padding-left: 55px;
				padding-right: 20px;
			}
		}
	}
	body.screen-t {
		.all-projects-page-template {
			.for-title {
				.featured-projects-sliders-all {
					white-space: normal;
					width: calc(100% - 60px);
				}
			}
		}
	}
	body.screen-t .all-projects-page-template .for-title .featured-projects-sliders-all .slide {
		width: 100%;
		margin-bottom: 16px;
	}
	.all-projects-page-template {
		.featured-projects-slider-container {
			.for-title {
				.featured-projects-sliders-all {
					.slide {
						.bg-image {
							height: 464px;
						}
					}
				}
			}
		}
	}
	body.screen-t .all-projects-page-template .for-title {
		display: none;
	}
	body.screen-t .all-projects-page-template .for-title[data-filter="featured"] {
		display: block;
	}
	.all-projects-page-template {
		.featured-projects-slider-container {
			.for-title {
				.featured-projects-sliders-all {
					.slide {
						.bg-image {
							.logo {
								height: 100%;
								max-height: 150px;
								width: 80%;
							}
						}
					}
				}
			}
		}
	}
	.all-projects-page-template {
		.featured-projects-slider-container {
			.for-title {
				.featured-projects-sliders-all {
					.slide {
						.bg-image {
							.logo {
								img {
									max-width: 100%;
									max-height: 100%;
									width: 100%;
									height: 100%;
									object-fit: contain;
									object-position: center;
								}
							}
						}
					}
				}
			}
		}
	}
	.all-projects-page-template {
		.featured-projects-slider-container {
			.for-title {
				h6 {
					display: none;
				}
				.featured-projects-slider {
					.slide {
						margin-right: 0;
					}
					.slide-next {
						display: none;
					}
				}
			}
		}
	}
	
	#fullpage .project-item .swipe-f-pr {
		display: block;
	}
	.dropdown-container {
		display: block;
	}
	// all projects 
	.all-projects-page-template {
		padding-top: 125px;
		
	}
}

// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-down(xs) {

	// culture 
	.culture-page-template {
		.clients {
			.logos {
				img {
					margin: 25px 0;
					width: calc(100% / 3);
				}
			}
		}
		.mobile-collage {
			.slick-slide {
				&>img {
					display: none;
				}
			}
			.sliders-art-mob {
				.video-bg {
					.over-image {
						.name-and-title {
							bottom: 30px;
							left: 30px;
							.name {
								font-size: 24px;
							}
							.title {
								font-size: 12px;
							}
						}
					}
				}
				.zoon-anim {
					.video-on-top {
						position: relative;
						z-index: 3;
						width: 100%;
						top: 0;
						left: 0;
						-webkit-transform: unset;
						transform: unset;
					}
					.copy-on-top {
						z-index: 4;
						top: 58%;
						left: 10%;
						transform: unset;
						transition: 0.5s;
						opacity: 1;
					}
					.over-image {
						display: none;
					}
					&.play-active {
						.copy-on-top {
							opacity: 0;
						}
					}
				}
				.slick-dots {
					top: calc(100% + 18px);
					bottom: unset;
					left: 50%;
					transform: translateX(-50%);
					opacity: 1 ;
					transition: 0.5s;
					width: 100%;
					&.play-active {
						opacity: 1;
					}
					li {
						a {
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	.collaborations-page-template {
		.clients {
			.logos {
				img {
					margin: 25px 0;
					width: calc(100% / 3);
				}
			}
		}
		.mobile-collage {
			.slick-slide {
				&>img {
					display: none;
				}
			}
			.sliders-art-mob {
				.zoon-anim {
					.video-on-top {
						position: relative;
						z-index: 3;
						width: 100%;
						top: 0;
						left: 0;
						-webkit-transform: unset;
						transform: unset;
					}
					.copy-on-top {
						z-index: 4;
						top: 58%;
						left: 10%;
						transform: unset;
						transition: 0.5s;
						opacity: 1;
					}
					.over-image {
						display: none;
					}
					&.play-active {
						.copy-on-top {
							opacity: 0;
						}
					}
				}
				.slick-dots {
					top: unset;
					bottom: 7%;
					opacity: 1;
					transition: 0.5s;
					&.play-active {
						opacity: 0;
					}
				}
			}
		}
	}
	.join-team {
		padding: 40px 10px;
	}
	// project internal page 
	
}

