/*--------------------------------------------------------------
# Social Media Icon Colors
--------------------------------------------------------------*/
$facebook_color: hsla(222, 47%, 40%, 1); // #365397
$twitter_color: hsla(198, 100%, 47%, 1); // #00a9f1
$linkedin_color: hsla(203, 100%, 35%, 1); // #006db3
$apple_color: hsla(0, 0%, 45%, 1); // #737373
$google_color: hsla(217, 89%, 61%, 1); // #4285f4
$google_plus_color: hsla(8, 74%, 53%, 1); // #e0452c
$youtube_color: hsla(0, 100%, 60%, 1); // #ff3333
$vimeo_color: hsla(200, 82%, 61%, 1); // #48b6ed
$pinterest_color: hsla(0, 78%, 45%, 1); // #ce1a19
$yelp_color: hsla(5, 100%, 38%, 1); // #c30f00
$dribbble_color: hsla(338, 82%, 60%, 1); // #ed4584
$amazon_color: hsla(36, 100%, 50%, 1); // #ff9700
$ebay_color: hsla(219, 89%, 30%, 1); // #083790
$skype_color: hsla(198, 100%, 48%, 1); // #00acf4
$instagram_color: hsla(208, 46%, 41%, 1); // #396d9a
$dropbox_color: hsla(206, 89%, 46%, 1); // #0d84de
$flickr_color: hsla(334, 100%, 46%, 1); // #ea0066
$github_color: hsla(0, 0%, 18%, 1); // #2f2f2f
$basecamp_color: hsla(121, 38%, 58%, 1); // #6bbd6d
$tumblr_color: hsla(210, 37%, 30%, 1); // #304c68
$foursquare_color: hsla(206, 72%, 45%, 1); // #207dc5
$box_color: hsla(204, 83%, 40%, 1); // #1277bc

.social-media {
  display: block;
  margin: 0 auto;
  text-align: center;

  .social-media-item {
	display: inline-block;
	background-color: $primary;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin-right: 10px;
	position: relative;
	&:last-child{
	  margin-right: 0;
	}
  }

  li {
	margin: 0;
	padding: 0;
  }
}

.social-icons {
  .icons {
	height: 15px;
	width: 15px;
	display: block;
	background-repeat: no-repeat;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	line-height: 0;
	-webkit-mask-size: cover;
	mask-size: cover;
	background-color: $white;
  }

  .twitter {
	-webkit-mask: url("../images/social/twitter.svg") no-repeat 50% 50%;
	mask: url("../images/social/twitter.svg") no-repeat 50% 50%;
	@extend .icons;
  }

  .instagram {
	-webkit-mask: url("../images/social/instagram.svg") no-repeat 50% 50%;
	mask: url("../images/social/instagram.svg") no-repeat 50% 50%;
	@extend .icons;
  }

  .facebook {
	-webkit-mask: url("../images/social/facebook.svg") no-repeat 50% 50%;
	mask: url("../images/social/facebook.svg") no-repeat 50% 50%;
	@extend .icons;
  }

  .youtube {
	-webkit-mask: url("../images/social/youtube.svg") no-repeat 50% 50%;
	mask: url("../images/social/youtube.svg") no-repeat 50% 50%;
	@extend .icons;
  }
}

/*--------------------------------------------------------------
# Share Posts Icons
--------------------------------------------------------------*/
.share-social-media {
  .social-media-item {
	display: inline-block;
  }

  .social-media-items {
	display: inline-block;
	background-color: $primary;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin-right: 10px;
	position: relative;
  }
}

.social-share-icons {
  .icons-share {
	height: 15px;
	width: 15px;
	display: block;
	background-repeat: no-repeat;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	line-height: 0;
	-webkit-mask-size: cover;
	mask-size: cover;
	background-color: $white !important;
  }

  .fa-twitter {
	-webkit-mask: url("../images/social/twitter.svg") no-repeat 50% 50%;
	mask: url("../images/social/twitter.svg") no-repeat 50% 50%;
	@extend .icons-share;
  }

  .fa-facebook {
	-webkit-mask: url("../images/social/facebook.svg") no-repeat 50% 50%;
	mask: url("../images/social/facebook.svg") no-repeat 50% 50%;
	@extend .icons-share;
  }

  .fa-linkedin {
	-webkit-mask: url("../images/social/linkedin.svg") no-repeat 50% 50%;
	mask: url("../images/social/linkedin.svg") no-repeat 50% 50%;
	@extend .icons-share;
  }
}

.social-media-lists {
  display: inline-block;
}

.social-media-lists ul,
.social-media-lists li {
  margin: 0;
  padding: 0;
}

.social-media-lists .social-media-item .fa {
  height: 28px;
  width: 28px;
  color: $white;
  padding: 4px;
  text-align: center;
}

.share-social-media .fa-facebook {
  background-color: $facebook_color;
}

.share-social-media .fa-twitter {
  background-color: $twitter_color;
}

.share-social-media .fa-envelope-o {
  background-color: $github_color;
}

.share-social-media .fa-linkedin {
  background-color: $linkedin_color;
}

.social-media-lists .fa-facebook:before,
.social-media-lists .fa-twitter:before,
.social-media-lists .fa-envelope-o:before,
.social-media-lists .fa-linkedin:before {
  font-size: 14px;
}
