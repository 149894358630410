.site-footer {
	background-color: $light-black;
	padding-top: 93px;
	position: relative;
	.back-top-top-ms {
		position: absolute;
		right: 80px;
		top: 100px;
		z-index: 2;
	}
	// z-index: -1;
    // position: absolute;
    // width: 100%;
	// margin-top: 29px;
	.container-fluid {
		&>.row {
			align-items: center;
		}
	}
	.top-row-footer-flex {
		display: flex;
		justify-content: space-between;
		padding-bottom: 100px;
	}
	.bottom-row-footer-flex {
		display: flex;
		justify-content: space-between;
		padding-bottom: 30px;
	}
	.right-side {
		padding-right: 48px
	}
	.left-side {
		padding-left: 60px;
		.copy {
			h3 {
				color: $primary;
				font-size: 60px;
			}
			p {
				color: $white;
				font-size: 16px;
			}
		}
		.logo-footer {
			margin-bottom: 20px;
		}
		.address {
			display: flex;
			padding-bottom: 60px;
			div {
				&:first-child {
					padding-right: 40px;
				}
			}
			p {
				font-size: 16px;
				color: $white;
				a {
					color: $white;
				}
			}
		}
		.disclaimer {
			p {
				font-size: 10px;
				color: $white;
			}
		}
	}
	.col-md-4 {
		margin-bottom: 66px;
	}
	#footer-nav {
		padding-top: 75px;
		ul {
			columns: 2;
			-webkit-columns: 2;
			-moz-columns: 2;
			display: block;
			width: fit-content;
			margin-left: 0;
			li {
				padding-right: 45px;
				width: fit-content;
				a {
					color: $white;
				}
			}
		}
	}
	.social {
		// padding-top: 36px;
		width: 350px;
		max-width: 100%;
		h4 {
			color: $primary;
			font-size: 16px;
			letter-spacing: 0;
			margin-bottom: 14px;
		}
		.social-media {
			text-align: left;
			display: flex;
			.social-media-item {
				background-color: transparent;
				margin-right: 20px;
				display: flex;
				justify-content: center;
				a {
					color: $white;
				}
			}
		}
	}
}
