/*!
 * Montana Steele Theme
 * Copyright 2019 Montana Steele
 */

// Project Styles
// Remember to comment out unnecessary styles

// Utilities
@import "utilities/default";
@import "utilities/variables";

// Bootstrap
@import "bootstrap/scss/bootstrap";

// Include Bourbon
@import "tools/bourbon/bourbon";
 
// Utilities
@import "utilities/typography";
@import "utilities/buttons";
@import "utilities/form";
@import "utilities/libraries";
@import "utilities/social";
@import "utilities/map";
@import "utilities/slider";
@import "utilities/spacing";
@import "utilities/popup";
@import "utilities/player";
@import "utilities/float-labels";
@import "utilities/hamburgers/hamburgers";

// Sections
@import "sections/archive";
@import "sections/404";
@import "sections/header";
@import "sections/footer";
@import "sections/page";
@import "sections/single";
@import "sections/navbar";

// Shortcodes
@import "shortcodes/blog";

// Breakpoints
// For more info visit
// https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
@import "breakpoints/breakpoint-between";
@import "breakpoints/breakpoint-down";
@import "breakpoints/breakpoint-only";
@import "breakpoints/breakpoint-up";
