.navbar-mont {
	mix-blend-mode: difference;
	padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 15;
    height: 0;
    background-color: transparent;
	.logo-top {
		position: fixed;
		top: 38px;
		left: 30px;
		padding-left: 57px;
		display: flex;
		height: fit-content;
		transition: 2.5s;
		transform: scale(1);
		&>div {
			height: 20px;
			height: fit-content;
			overflow: hidden;
			transition: .5s;
			a {
				height: fit-content;
				display: block;
				img {
					height: 100%;
				}
			}
			&.montana {
				width: 109px;
				transition: 1.5s;
				&.firefox-bg-fix {
					background-color: white;
				}
				// background-color: white;
				// position: relative;
    			// z-index: 1;  /* This forces a new stacking context */
				background-color: transparent;
				a {
					height: fit-content;
					width: 109px;
					svg {
						mix-blend-mode: difference;
					}
					path {
						transition: 1.5s;
						fill: $white;
						opacity: 1;
						
						
					}
				}
			}
		}
	}
	&.scroll-active { 
		.logo-top {
			transform: scale(1.3);
			&>div {
				&.montana {
					width: 23px;
					margin-left: -1px;
					a {
						path {
							opacity: 0;
							&:first-child {
								opacity: 1;
							}
						}
					}
				}
				&.steele {
					width: 14px;
					a {
						path {
							opacity: 0;
							&:first-child {
								opacity: 1;
							}
						}
					}
				}
			}
		}
		&.menu-open {
			.logo-top {
				&>div {
					&.montana {
						width: 109px;
						a {
							path {
								opacity: 1;
							}
						}
					}
					&.steele {
						width: 77px;
						a {
							path {
								opacity: 1;
							}
						}
					}
				}
			}
		}
		&.dark-menu, &.culture-menu {
			.logo-top{
				&>div{
					&.montana {
						a {
							path {
								fill: $white;
							}
						}
					}
				}
			}
		}
	}
	&.menu-open {
		&.scroll-active {
			.logo-top {
				transform: unset;
			}
		}
	}
}
.navbar {
	// mix-blend-mode: difference;
	padding: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 15;
	height: 0;
	background-color: transparent;
	&.menu-open {
		&.scroll-active {
			.row {
				.col-12 {
					.logo-top {
						transform: unset;
					}
				}
			}
		}
	}
	.row {
		.col-12 {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			.logo-top {
				position: fixed;
				top: 38px;
				left: 30px;
				padding-left: 57px;
				display: flex;
				height: fit-content;
				transition: 2.5s;
				transform: scale(1);
				&>div {
					height: 20px;
					height: fit-content;
					overflow: hidden;
					transition: .5s;
					a {
						height: fit-content;
						display: block;
						img {
							height: 100%;
						}
					}
					&.montana {
						width: 109px;
						transition: 1.5s;
						// background-color: white;
						background-color: transparent;
						opacity: 0;
						a {
							height: fit-content;
							width: 109px;
							svg {
								// mix-blend-mode: difference;
							}
							path {
								transition: 1.5s;
								fill: $white;
								opacity: 1;
								
								
							}
						}
					}
					&.steele {
						width: 77px;
						transition: 1.5s;
						transition-delay: 0.4s;
						isolation: isolate;
						mix-blend-mode: unset;
						a {
							height: fit-content;
							width: 77px;
							svg {
								margin-top: -1px;
								isolation: isolate;
								mix-blend-mode: unset;
							}
							path {
								transition: 1.5s;
								opacity: 1;
							}
						}
					}
				}
			}
			.menu {
				display: flex;
				// align-items: center;
				margin-right: 47px;
				position: relative;
				position: fixed;
				top: 38px;
				right: 30px;
				.hamburger {
					opacity: 0;
					padding: 0;
					position: absolute;
					z-index: -1;
					transition: 0s;
					height: 22px;
					width: 31px;
					margin-top: 10px;
				}
			}
			.navbar-nav {
				opacity: 1;
				z-index: 1;
				transition: 0.5s;
				.nav-link {
					padding-right: 28px;
					padding-left: 28px;
					color: #fff;
					font-size: 18px;
				}
			}
		}
	}
	&.dark-menu, &.culture-menu {
		&:not(.dark-exception) {
			.hamburger-lines .line {
				background-color: $light-black;
				transition: 1.5s;
				&.line2 { 
					transition: transform 0s, background-color 1.5s;
				}
			}
			.row {
				.col-12 {
					.navbar-nav {
						.nav-link {
							transition: 0.5s;
							color: $light-black;
						}
					}
					.logo-top{
						&>div{
							&.montana {
								a {
									path {
										// fill: $light-black;
									}
								}
							}
						}
					}
				}
			}
		}
		
	}
	&.scroll-active {
		.row {
			.col-12 {
				.navbar-nav {
					opacity: 0;
					z-index: -1;
					transition: 0s;
					display: none;
				}
				.menu {
					.hamburger {
						opacity: 1;
						z-index: 1;
						transition: 0.5s;
						position: relative;
						margin-right: 28px;
					}
				}
				.logo-top {
					transform: scale(1.3);
					&>div {
						&.montana {
							width: 23px;
							a {
								path {
									opacity: 0;
									&:first-child {
										opacity: 1;
									}
								}
							}
						}
						&.steele {
							width: 14px;
							a {
								path {
									opacity: 0;
									&:first-child {
										opacity: 1;
									}
								}
							}
						}
					}
				}
			}
		}
		&.menu-open {
			.row {
				.col-12 {
					.logo-top {
						&>div {
							&.montana {
								width: 109px;
								a {
									path {
										opacity: 1;
									}
								}
							}
							&.steele {
								width: 77px;
								a {
									path {
										opacity: 1;
									}
								}
							}
						}
					}
				}
			}
			&.dark-menu, &.culture-menu {
				&:not(.dark-exception) {
					.hamburger-lines .line {
						background-color: $white;
						transition: 1.5s;
						&.line2 {
							transition: transform 0s, background-color 1.5s;
						}
					}
					.row {
						.col-12 {
							.logo-top{
								&>div{
									&.montana {
										a {
											path {
												fill: $white;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}


	.navbar-toggler.hamburger[aria-expanded=true] {
		@extend .is-active;
	}

	.navbar-toggler {
		border-left: 1px solid $light-grey;
	}



	.current-menu-item {
		.nav-link {
		color: $primary !important;
		}

		.dropdown-item {
		color: $primary !important;
		}
	}

	.dropdown-menu {
		border: 1px solid $light-grey;
	}

	.dropdown-item {
		&:hover,
		&:focus {
		background-color: transparent;
		}

		&:active,
		&.active {
		color: inherit;
		}
	}
	// hamburger
	.checkbox {
		position: absolute;
		display: block;
		height: 22px;
		width: 31px;
		top: 0;
		left: 0;
		z-index: 5;
		opacity: 0;
		cursor: pointer;
	}
	
	.hamburger-lines {
		display: block;
		height: 22px;
		width: 31px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	
	.hamburger-lines .line {
		display: block;
		height: 1px;
		width: 100%;
		border-radius: 10px;
		background-color: $white;
		transition: 1.5s;
	}
	
	.hamburger-lines .line1 {
		transform-origin: 0% 0%;
		transition: transform 0.4s ease-in-out, background-color 1.5s;
	}
	
	.hamburger-lines .line2 {
		transition: transform 0.2s ease-in-out, background-color 1.5s;
	}
	
	.hamburger-lines .line3 {
		transform-origin: 0% 100%;
		transition: transform 0.4s ease-in-out, background-color 1.5s;
	}
	
	.navbar .menu-items {
		padding-top: 120px;
		box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
		height: 100vh;
		width: 100%;
		transform: translate(-150%);
		display: flex;
		flex-direction: column;
		margin-left: -40px;
		padding-left: 50px;
		transition: transform 0.5s ease-in-out;
		text-align: center;
	}
	
	.navbar .menu-items li {
		margin-bottom: 1.2rem;
		font-size: 1.5rem;
		font-weight: 500;
	}
	
	.logo {
		position: absolute;
		top: 5px;
		right: 15px;
		font-size: 1.2rem;
		color: #0e2431;
	}
	
	input[type="checkbox"]:checked ~ .menu-items {
		transform: translateX(0);
	}
	
	input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
		transform: rotate(45deg);
	}
	
	input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
		transform: scaleY(0);
	}
	
	input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
		transform: rotate(-45deg);
	}
	
	input[type="checkbox"]:checked ~ .logo{
		display: none;
	}

}


.menu-popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $light-black;
	display: flex;
	align-items: center;
	opacity: 0;
	z-index: -1;
	transition: 0.5s;
	&.menu-open {
		opacity: 1;
		z-index: 10;
		.menu-left-side {
			.social-media {
				text-align: left;
				padding-left: 15px;
				margin-top: 88px;
				.social-media-item {
					background-color: transparent;
					a {
						color: $dark-grey;
					}
				}
			}
			#header-nav {
				.navbar-nav {
					li {
						opacity: 1;
						transform: translate(0px) rotate(0deg);
						transition: 1.5s;
						&:first-child {
							transition-delay: 0.3s;
						}
						&:nth-child(2) {
							transition-delay: 0.6s;
						}
						&:nth-child(3) {
							transition-delay: 0.9s;
						}
						&:nth-child(4) {
							transition-delay: 1.2s;
						}
						&:nth-child(5) {
							transition-delay: 1.5s;
						}
						&:nth-child(6) {
							transition-delay: 1.8s;
						}
						&:nth-child(7) {
							transition-delay: 2.1s;
						}
					}
				}
			}
			
		}
		img {
			opacity: 1;
			transform: translate(0) rotate(0deg);
			transition: 1.5s;
			transition-delay:2;
		}
	} 
	.menu-left-side {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: flex-end;
		#header-nav {
			.navbar-nav {
				.nav-link {
					font-size: 48px;
					letter-spacing: -0.08em;
					font-weight: 700;
					color: $white;
					padding: 15px;
					line-height: 1;
					position: relative;
					text-transform: lowercase;
					&::before {
						content: '';
						width: 33px;
						height: 3px;
						background-color: $primary;
						position: absolute;
						right: 100%;
						top: 50%;
						transform: translateY(-50%);
						opacity: 0;
						transition: 0.5s;
					}
					&:hover {
						&::before {
							opacity: 1;
						}
					}
				}
				li {
					opacity: 0;
					transform: translate(10px, -20px) rotate(-1deg);
					transition: 0s;
					transition-delay:0;
				}
			}	
		}
	}
	img {
		opacity: 0;
		transform: translate(10px, -20px) rotate(-1deg);
		transition: 0s;
		transition-delay:0;
	}
}



