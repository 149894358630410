.fl-form .fl-wrap {
  position: relative;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fl-form input.fl-input,
.fl-form select.fl-select,
.fl-form textarea.fl-textarea {
  width: 100%;
  outline: 0;
  font-size: 16px;
  line-height: 1.5;
  background-color: $white;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}

.fl-form input.fl-input:-moz-placeholder,
.fl-form select.fl-select:-moz-placeholder,
.fl-form textarea.fl-textarea:-moz-placeholder {
  color: $dark-grey;
}

.fl-form input.fl-input::-moz-placeholder,
.fl-form select.fl-select::-moz-placeholder,
.fl-form textarea.fl-textarea::-moz-placeholder {
  color: $dark-grey;
}

.fl-form input.fl-input:-ms-input-placeholder,
.fl-form select.fl-select:-ms-input-placeholder,
.fl-form textarea.fl-textarea:-ms-input-placeholder {
  color: $dark-grey;
}

.fl-form input.fl-input::-webkit-input-placeholder,
.fl-form select.fl-select::-webkit-input-placeholder,
.fl-form textarea.fl-textarea::-webkit-input-placeholder {
  color: $dark-grey;
}

.fl-form select.fl-select {
  position: relative;
  color: $dark-grey;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.fl-form select.fl-select::-ms-expand {
  display: none;
}

.fl-form .fl-is-active input.fl-input,
.fl-form .fl-is-active select.fl-select,
.fl-form .fl-is-active textarea.fl-textarea {
  color: #444;
  background-color: $white;
}

.fl-form .fl-has-focus input.fl-input,
.fl-form .fl-has-focus select.fl-select,
.fl-form .fl-has-focus textarea.fl-textarea {
  background-color: $white;
  border-color: $primary;
}

.fl-form label.fl-label {
  opacity: 0;
  visibility: hidden;
  display: block;
  position: absolute;
  top: -12px;
  left: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: $dark-grey;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}

body[dir=rtl] .fl-form label.fl-label {
  left: unset;
  right: 0;
}

.fl-form .fl-is-active label.fl-label {
  opacity: 1;
  visibility: visible;
}

.fl-form .fl-has-focus label.fl-label {
  color: $primary;
}

.fl-form .fl-is-required:before {
  opacity: 1;
  content: '*';
  display: block;
  position: absolute;
  top: 1px;
  right: 15px;
  font-size: 16px;
  line-height: 1.75;
  color: $error;
  transition: all 0.2s ease-in-out;
  padding: 6px 0 0;
  z-index: 1;
}

body[dir=rtl] .fl-form .fl-is-required:before {
  right: unset;
  left: 15px;
}

.fl-form .fl-is-required.fl-is-active:before {
  opacity: 0;
}

.fl-form.fl-style-1 input.fl-input,
.fl-form.fl-style-1 select.fl-select,
.fl-form.fl-style-1 textarea.fl-textarea {

}

.fl-form.fl-style-1 select.fl-select {

}

.fl-form.fl-style-1 .fl-is-active input.fl-input,
.fl-form.fl-style-1 .fl-is-active select.fl-select,
.fl-form.fl-style-1 .fl-is-active textarea.fl-textarea {

}

.fl-form.fl-style-1 label.fl-label {
  top: 1px;
  left: 10px;
  background-color: $float-label-background;
  padding: 12px 3px;
}

.fl-form.fl-style-1 label.fl-label:before {
  content: '';
  display: block;
  position: absolute;
  top: 19px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: $white;
  z-index: -1;
}

.fl-form.fl-style-1 .fl-is-active label.fl-label {
  top: -9px;
  padding: 3px;
}

.fl-form.fl-style-1 .fl-is-active label.fl-label:before {
  top: 9px;
}

body[dir=rtl] .fl-form.fl-style-1 label.fl-label {
  left: unset;
  right: 10px;
}

.fl-form .fl-wrap-select:after {
  content: '';
  position: absolute;
  display: block;
  top: 1px;
  right: 6px;
  height: calc(100% - 2px);
  width: 12px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 16'%3E%3Cpath fill='%23bbb' d='M 4 0 L 0 6.5 L 8 6.5 L 4 0 z M 0 9.5 L 4 16 L 4 16 L 8 9.5 z'/%3E%3C/svg%3E") no-repeat;
  background-position: 100% 50%;
  background-size: 7px 14px;
  z-index: 2;
}

.fl-wrap-select {
  select {
	background-image: none;
  }
}

body[dir=rtl] .fl-form .fl-wrap-select:after {
  right: unset;
  left: 6px;
}
