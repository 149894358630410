.archive {
  article.hentry {
	padding: 15px 0;
	border-bottom: 1px solid $grey;
  }
}

.search {
  @extend .archive;
}
