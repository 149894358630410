/*
/* only include the WOFF and WOFF2 formats
*/

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(".././../assets/fonts/inter/Inter-Regular.woff2") format("woff2"),
  url(".././../assets/fonts/inter/Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(".././../assets/fonts/inter/Inter-Bold.woff2") format("woff2"),
  url(".././../assets/fonts/inter/Inter-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(".././../assets/fonts/inter/Inter-Medium.ttf") format("truetype");
}
