.single {
//   .site-content {
// 	max-width: #{$container_md}px;
//   }

  .entry-content {
	a {
	  border-bottom: 1px solid $primary;
	}

	ul {
	  padding: 20px;
	  margin: 0;
	  list-style: inherit;
	}
  }
}
