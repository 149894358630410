// Large play button (video only)
.plyr__control--overlaid {
  background: $primary;

  &:hover,
  &:focus {
	background: $primary;
  }
}

.plyr--full-ui input[type=range] {
  color: $primary;
}

.plyr--video .plyr__controls .plyr__control:hover {
  background: $primary;
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  background: $primary;
}
