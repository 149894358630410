/**
 * contact form 7 styles
 */
.wpcf7 {
  margin: 0;
  padding: 0;

  .screen-reader-response {
	position: absolute;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	width: 1px;
	margin: 0;
	padding: 0;
	border: 0;
  }

  .wpcf7-response-output {
	margin: 2em 0.5em 1em;
	padding: 0.2em 1em;
	border: 2px solid $danger;
  }

  .wpcf7-mail-sent-ok {
	border: 2px solid $success;
  }

  .wpcf7-mail-sent-ng,
  .wpcf7-aborted {
	border: 2px solid $danger;
  }

  .wpcf7-spam-blocked {
	border: 2px solid $warning;
  }

  .wpcf7-validation-errors,
  .wpcf7-acceptance-missing {
	border: 2px solid $warning;
  }

  .wpcf7-form-control-wrap {
	position: relative;
  }

  .wpcf7-not-valid-tip {
	color: $danger;
	font-size: 1em;
	font-weight: normal;
	display: block;

	.wpcf7-not-valid-tip {
	  position: absolute;
	  top: 20%;
	  left: 20%;
	  z-index: 100;
	  border: 1px solid $danger;
	  background: #fff;
	  padding: .2em .8em;
	}
  }

  .wpcf7-list-item {
	display: inline-block;
	margin: 0 0 0 1em;
  }

  .wpcf7-list-item-label::before,
  .wpcf7-list-item-label::after {
	content: " ";
  }

  .wpcf7-display-none {
	display: none;
  }

  .ajax-loader {
	visibility: hidden;
	display: inline-block;
	width: 16px;
	height: 16px;
	border: none;
	padding: 0;
	margin: 0 0 0 4px;
	vertical-align: middle;
  }

  .ajax-loader.is-active {
	visibility: visible;
  }

  .ajax-error {
	display: none;
  }

  .placeheld {
	color: #888;
  }

  .wpcf7-recaptcha iframe {
	margin-bottom: 0;
  }

  input[type="file"] {
	cursor: pointer;
  }

  input[type="file"]:disabled {
	cursor: default;
  }
}

/**
 * Search form styles
 */
.search-form {
  input[type='search'] {
	@extend .form-control;
  }

  .search-submit {
	@extend .btn;
	@extend .btn-primary;
	margin-top: -4px;
  }
}

/**
 * style radio buttons
 */
[type="radio"] {
  &:checked,
  &:not(:checked) {
	position: absolute;
	left: -9999px;
  }

  &:checked + label,
  &:not(:checked) + label {
	position: relative;
	padding-left: 28px;
	cursor: pointer;
	line-height: 20px;
	display: inline-block;
  }

  &:checked + label:before,
  &:not(:checked) + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 18px;
	height: 18px;
	border: 1px solid $grey;
	border-radius: 100%;
	background: $white;
  }

  &:checked + label:after,
  &:not(:checked) + label:after {
	content: '';
	width: 10px;
	height: 10px;
	background: $primary;
	position: absolute;
	top: 4px;
	left: 4px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
  }

  &:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
  }

  &:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
  }
}

.disabled {
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
	background-color: $grey;
  }
}


// style the checkboxes
[type="checkbox"] {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
	position: relative;
	cursor: pointer;
	padding: 0;
  }

  // Box.
  & + label:before {
	content: '';
	margin-right: 4px;
	display: inline-block;
	vertical-align: text-top;
	width: 20px;
	height: 20px;
	background: white;
	border: 1px solid $grey;
  }

  // Box focus
  &:focus + label:before {
	box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
	background: $primary;
  }

  // Disabled state label.
  &:disabled + label {
	color: $grey;
	cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
	box-shadow: none;
	background: $grey;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
	content: '';
	position: absolute;
	left: 5px;
	top: 12px;
	background: $white;
	width: 2px;
	height: 2px;
	box-shadow: 2px 0 0 $white,
	4px 0 0 white,
	4px -2px 0 $white,
	4px -4px 0 $white,
	4px -6px 0 $white,
	4px -8px 0 $white;
	transform: rotate(45deg);
  }
}

// modify bootstrap checkbox
.form-check {
  padding-left: 0;
}

.form-check-input {
  margin-top: 0;
  margin-left: 0;
}

// select fixes
select {
  appearance: none;
  background-color: transparent;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2216%22%20height%3D%228%22%20viewBox%3D%220%200%2016%208%22%3E%3Cpolygon%20fill%3D%22%234D4D4D%22%20points%3D%228%2C0%2016%2C0%2012%2C4%208%2C8%204%2C4%200%2C0%20%22/%3E%3C/svg%3E);
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  border-radius: 0;
  padding: 0.25em 0.4em;
  padding-right: 1.5em;

  &:focus,
  &:hover {
	outline: none;
  }

  &:-moz-focusring {
	color: transparent;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  select::-ms-expand {
	display: none;
  }
  select:focus::-ms-value {
	background: transparent;
	color: grey;
  }
}
