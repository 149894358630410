// Create default paddings and margins
// Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
	.m-#{str-slice($side, 0, 1)}-#{$space} {
	  margin-#{$side}: #{$space}px !important;
	}

	.p-#{str-slice($side, 0, 1)}-#{$space} {
	  padding-#{$side}: #{$space}px !important;
	}
  }
}
