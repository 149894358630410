// Extra small devices (portrait phones, less than 576px)
@include media-breakpoint-up(xs) {

}

// Small devices (landscape phones, 576px and up)
@include media-breakpoint-up(sm) {

}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {

}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    .screen-t { 
        .culture-page-template {
            .mobile-collage {
                display: block;
                margin-bottom: 0px;
                margin-top: 70px;
                .sliders-art-mob .video-bg .over-image .name-and-title .title {
                    font-size: 8px;
                }
            }
            .back-zoom-height {
                display: none;
                height: 0;
                margin-top: 0;
            }
        }
        .collaborations-page-template {
            .mobile-collage {
                display: block;
                margin-bottom: 0px;
                margin-top: 70px;
                img {
                    width: 100%;
                }
            }
            .back-zoom-height {
                display: none;
                height: 0;
                margin-top: 0;
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {

}

@media only screen and (min-width: 1920px) {
    .container {
        max-width: 80vw;
    }
    .all-projects-page-template {
        .featured-projects-slider-container {
            .for-title {
                h6 {
                    margin-left: calc((100vw - 2000px) / 2 + 120px + 15px);
                }
                .featured-projects-slider {
                    margin-left: calc((100vw - 2000px) / 2 + 120px + 15px);
                    .slick-list {
                    }
                }
                .master-planned-communities-projects-slider, .high-rise-projects-slider, .low-rise-projects-slider, .purpose-built-rentals-projects-slider, .hotel-entertainment-projects-slider, .hospitality-commercial-projects-slider, .public-service-projects-slider, .corporate-real-estate-slider {
                    margin-left: calc((100vw - 2000px) / 2 + 120px + 15px);
                    .slick-list {
                    }
                }
            }
        }
    }
    .navbar {
        .row {
            .col-12 {
                .navbar-nav {
                    .nav-link {
                        font-size: 22px !important;
                    }
                }
            }
        }
    }
    .full-project {
        .logo-dark {
            padding-bottom: 120px;
        }
        h1 {
            font-size: calc((65vw - 4.5rem) / 7) !important;
        }
        h3 {
            font-size: calc((11vw - 4.5rem) / 7) !important;
        }
        .project-brand {
            width: 35vw;
        }
        .about-project {
            .about-top {
                max-width: 60vw;
            }
            .about-video {
                margin-top: 136px;
            }
        }
        .projects-slider .slick-list {
            padding-left: calc((100vw - 80vw) / 2 - 5px);
        }
        .top-project-banner .copy-top-screen .project-and-cat span {
            font-size: calc((10vw - 4.5rem) / 7) !important;
        }
        p {
            font-size: calc((10vw - 4.5rem) / 7) !important;
        }
        .copy-bottom-screen>p {
            font-size: calc((11vw - 4.5rem) / 7) !important;
        }
        .social-campaign {
            .social-flex, .social-flex-pizza {
                .social-item {
                    min-width: 22vw;
                    &:first-child {
                        min-width: 19vw;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    .home-page-template .slick .slick-slide h2, .home-page-template .slick .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] h2 {
        font-size: calc((45vw - 4.5rem) / 7) !important;
    }
    .home-page-template .slick .slick-slide p, .home-page-template .slick .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] p {
        font-size: calc((11vw - 4.5rem) / 7) !important;
    }
    .home-page-template .dark-bg .slick .slick-dots {
        padding-left: calc(((100vw - 80vw) / 2) + (80vw / 12) + 15px);
    }
    .home-page-template .back-zoom-height .zoom-center-animation .on-top .gradient-for-copy div h2{
        font-size: calc((11vw - 4.5rem) / 7) !important;
    }
    .home-page-template .back-zoom-height .zoom-center-animation .on-top .gradient-for-copy div a {
        font-size: calc((5vw - 4.5rem) / 7) !important;
    }
    a.arrow {
        font-size: calc((10vw - 4.5rem) / 7) !important;
    }
    .home-page-template {
        h2 {
            font-size: calc((55vw - 4.5rem) / 7) !important;
        }
    }
    p {
        font-size: calc((10vw - 4.5rem) / 7) !important;
    }
    .capabilities-page-template .capabilities-banner .all-options a {
        font-size: calc((17vw - 4.5rem) / 7) !important;
    }
    .capabilities-page-template .capabilities-copy h5 {
        font-size: calc((10vw - 4.5rem) / 7) !important;
    }
    .capabilities-page-template .capabilities-copy h2 {
        font-size: calc((18vw - 4.5rem) / 7) !important;
    }
    .culture-page-template .culture-top h1 {
        font-size: calc((18vw - 4.5rem) / 7) !important;
    }
    .join-team h3 {
        font-size: calc((22vw - 4.5rem) / 7) !important;
    }
    .culture-page-template .awards h6 {
        font-size: calc((10vw - 4.5rem) / 7) !important;
    }
    .culture-page-template .card .card-header h5 .btn-link {
        font-size: calc((16vw - 4.5rem) / 7) !important;
    }
    ul li {
        font-size: calc((10vw - 4.5rem) / 7) !important;
    }
    .contact-page-template .contact-copy h6 {
        font-size: calc((10vw - 4.5rem) / 7) !important;
    }
    .contact-page-template .contact-copy h1 {
        font-size: calc((25vw - 4.5rem) / 7) !important;
    }
    .careers-page-template .careers-copy h6 {
        font-size: calc((10vw - 4.5rem) / 7) !important;
    }
    .culture-page-template {
        h2 {
            font-size: calc((28vw - 4.5rem) / 7) !important;
        }
    }
    .site-footer {
        p {
            font-size: calc((10vw - 4.5rem) / 7) !important;
        }
        .left-side {
            .copy {
                h3 {
                    font-size: calc((30vw - 4.5rem) / 7) !important;
                }
            }
        }
        #footer-nav ul li a {
            font-size: calc((10vw - 4.5rem) / 7) !important;
        }
        h4 {
            font-size: calc((10vw - 4.5rem) / 7) !important;
        }
    }
    .fa-2x {
        font-size: 1.5em;
    }
}
